<template>
    <v-bottom-sheet :value="value" persistent>
        <v-sheet>
            <div class="pa-3">
                <v-alert :type="item.resp ? 'success' : 'warning'">
                    <span class="font-weight-semibold">{{ data.message }}</span>
                </v-alert>

                <div v-if="data.data">
                    <p class="mb-1">RUT: <strong>{{ data.data.rut }}</strong></p>
                    <p class="mb-1">Asistente: <strong>{{ data.data.name }}</strong></p>
                    <p class="mb-1">Lista: <strong>{{ data.data.list }}</strong></p>
                    <p class="mb-1">Responsable: <strong>{{ data.data.sellerName }}</strong></p>
                </div>

                <v-row class="mt-4">
                    <v-col v-if="!item.resp">
                        <v-btn @click="onClose" block depressed large>
                            Continuar
                        </v-btn>
                    </v-col>
                    <v-col v-if="item.resp">
                        <v-btn @click="onReject()" :loading="loadingReject" :disabled="disabled" block depressed
                            outlined large color="red">
                            Rechazar
                        </v-btn>
                    </v-col>
                    <v-col v-if="item.resp">
                        <v-btn @click="onAccept()" :loading="loadingAccept" :disabled="disabled" block depressed
                            outlined large color="success">
                            Aceptar
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { updateTicket } from "@/api/scan.js";

export default {
    props: ['value', 'data'],

    data: () => ({
        item: {},
        disabled: false,
        loadingReject: false,
        loadingAccept: false
    }),

    watch: {
        data(obj) {
            if (Object.keys(obj).length > 0) {
                const resp = obj
                obj.resp = obj.status === 'rejected' ? false : true

                this.item = resp
            } else {
                this.item = {}
            }
        }
    },

    methods: {
        onClose() {
            this.item = {}
            this.$emit('input', false);
        },

        async onAccept() {
            try {
                this.disabled = true;
                this.loadingAccept = true;
                await updateTicket(this.$route.query.id, true, this.data.data.rut)
            } catch (error) {
                console.error(error)
            } finally {
                this.disabled = false;
                this.loadingAccept = false;
                this.onClose()
            }
        },

        async onReject() {
            try {
                this.disabled = true;
                this.loadingReject = true;
                await updateTicket(this.$route.query.id, false, this.data.data.rut)
            } catch (error) {
                console.error(error)
            } finally {
                this.disabled = false;
                this.loadingReject = false;
                this.onClose();
            }
        },
    },
}
</script>