<template>
    <div style="height: 100%;">
        <qrcode-stream :camera="paused" @detect="onDetect">
            <v-overlay :value="loading" opacity="0.6" absolute>
                <span class="font-weight-bold">Validando...</span>
            </v-overlay>
        </qrcode-stream>

        <v-snackbar v-model="snackbar" color="error" timeout="1500">
            Error al leer el código
        </v-snackbar>
    </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { getAttendeeByRut } from "@/api/scan.js";

export default {
    components: { QrcodeStream },

    data: () => ({
        paused: 'auto',
        loading: false,
        snackbar: false,
    }),

    methods: {
        async onDetect(detectedCode) {
            try {
                this.paused = 'off'
                this.loading = true;

                const rut = await this.getCodeContent(detectedCode);
                const result = await getAttendeeByRut(this.$route.query.id, rut);
                this.$emit("result", result)
            } catch (error) {
                console.error('ScanQR', error);
                this.snackbar = true;
            } finally {
                this.paused = 'auto';
                this.loading = false;
            }
        },

        async getCodeContent(value) {
            const resp = await value;
            const urlObj = new URL(resp.content);
            const attendeeRun = urlObj.searchParams.get('RUN');

            return attendeeRun;
        }
    },
}
</script>