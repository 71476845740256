import store from "@/store";
import { db } from "@/firebase";
import { doc, getDoc, writeBatch, increment } from "firebase/firestore";

const companyId = store.state.auth.user.companyId;
const eventReference = `companies/${companyId}/events`
const ticketReference = `companies/${companyId}/junction`
const attendeeReference = `companies/${companyId}/attendees`;

export const getAttendeeByRut = async (eventId, rut) => {
    try {
        let attendee = {}
        let ticket = {}

        // 1. Verificar si el RUT está en la lista negra o si es VIP
        const attendeeRef = doc(db, attendeeReference, rut);
        const attendeeSnap = await getDoc(attendeeRef);

        if (attendeeSnap.exists()) {
            attendee = attendeeSnap.data();

            if (attendee.disabled) {
                return { status: 'rejected', message: 'Acceso denegado, asistente bloqueado.', data: attendee };
            }

            if (attendee.level > 1) {
                return { status: 'approved', message: `Acceso Permitido, asistente ${attendee.category}.`, data: attendee };
            }
        }

        // 2. Buscar el ticket del asistente en el evento
        const ticketRef = doc(db, ticketReference, `${rut}_${eventId}`);
        const ticketDoc = await getDoc(ticketRef);

        if (ticketDoc.exists()) {
            ticket = ticketDoc.data();

            if (!ticket.activeTicket) {
                return { status: 'rejected', message: 'Acceso denegado, ticket no valido.', data: ticket };
            }
        } else {
            return { status: 'rejected', message: 'No tiene ticket para este evento.' };
        }

        return { status: 'approved', message: "Acceso Permitido", data: ticket };
    } catch (error) {
        console.log("Error al verificar el estado del asistente", error)
        throw error;
    }
}

export const updateTicket = async (eventId, action, rut) => {

    const ticketId = `${rut}_${eventId}`;
    
    const data = {
        disabled: true,
        scanUpdate: new Date(),
        ticketAccepted: action, // old
        activeTicket: false,
        ticket_entered: action,
        ticket_time: new Date()
    }

    try {
        const batch = writeBatch(db);

        // Update ticket
        const ticketRef = doc(db, ticketReference, ticketId);
        batch.update(ticketRef, data);

        // Update attendees
        const access = action ? 'attendeesAccepted' : 'attendeesRejected'
        const eventRef = doc(db, eventReference, eventId);
        batch.update(eventRef, { [access]: increment(1) });

        await batch.commit();
    } catch (error) {
        console.info(error)
        throw error
    }
}