<template>
    <div class="scan-view">
        <!-- header -->
        <v-app-bar class="scan-view__header" app>
            <v-btn @click="$router.go(-1)" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ $route.query.name }}</v-toolbar-title>
        </v-app-bar>

        <div class="scan-view__container">
            <!-- search -->
            <div class="scan-view__search"><scan-search @result="onResult" /></div>

            <!-- qrscan -->
            <scan-qr @result="onResult" />

            <!-- validation -->
            <scan-validation v-model="sheet" :data="result" @input="result = {}" />
        </div>
    </div>
</template>

<script>
import ScanQr from "./ScanQr.vue";
import ScanSearch from "./ScanSearch.vue";
import ScanValidation from './ScanValidation.vue'

export default {
    components: { ScanSearch, ScanQr, ScanValidation },

    data: () => ({
        sheet: false,
        result: {},
    }),

    methods: {
        onResult($event) {
            this.sheet = true;
            this.result = $event;
        }
    },
}
</script>

<style lang="css" scoped>
.scan-view__header {
    height: 56px !important;
}

.scan-view__container {
    height: calc(100vh - 56px);
    position: relative;
}

.scan-view__search {
    top: 18px;
    left: 18px;
    right: 18px;
    z-index: 9;
    position: absolute;
    border-radius: 6px;
    background-color: #1a1b1f;
}
</style>