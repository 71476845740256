<template>
    <v-form ref="form" @submit.prevent="onSubmit" :disabled="loading" class="search-attendee">
        <v-text-field v-model="rutInput" placeholder="RUT Asistente" :rules="[rules.required]" flat solo hide-details />
        <v-btn :loading="loading" :disabled="loading" color="primary" class="px-6" type="submit"
            depressed>Buscar</v-btn>
    </v-form>
</template>

<script>
import Rut from "rutjs";
import { rulesUser } from "@/assets/rules.js";
import { getAttendeeByRut } from "@/api/scan.js";

export default {
    data: () => ({
        rules: rulesUser,
        rutInput: '',
        loading: false,
        eventId: ''
    }),

    watch: {
        rutInput(newRut) {
            var rut = new Rut(newRut);
            this.rutInput = rut.getNiceRut(false);
        }
    },

    methods: {
        async onSubmit() {
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true;
                    const result = await getAttendeeByRut(this.eventId, this.rutInput);
                    this.$emit("result", result)
                } catch (error) {
                    console.error('ScanSearch', error)
                } finally {
                    this.loading = false;
                    this.rutInput = ''
                }
            } else {
                alert("Ingresar un rut válido")
            }
        }
    },

    created() {
        this.eventId = this.$route.query.id;
    },
}

</script>

<style lang="scss" scoped>
.search-attendee {
    border: 2px solid #5749f7;
    border-radius: 5px;
    overflow: hidden;
    display: flex;

    .v-btn {
        height: 44px;
        border-radius: 0;
    }
}
</style>